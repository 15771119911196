import gsap from "gsap"
class RedInterventionProcess extends HTMLElement {
    static register(tagName) {
        if ("customElements" in window) {
            customElements.define(tagName || "red-intervention-process", RedInterventionProcess);
        }
    }
    path
    steps
    timeline
    context
    constructor() {
        super()
        this.path = this.querySelector('.intervention-process__line path')
        this.steps = Array.from(this.querySelectorAll('.intervention-process__step'))
        this.timeline = gsap.timeline({
            scrollTrigger: {
                trigger: this.querySelector('.intervention-process__list'),
                start: 'top center',
                end: 'bottom-=200px center',
                scrub: 2,
                ease:'none'
            }
        })
    }
    disconnectedCallback() {
        this.context.revert()
    }
    connectedCallback() {
        this.context = gsap.context(() => {
            gsap.matchMedia().add('(min-width:1050px)', () => {
                gsap.set(this.path, {
                    drawSVG: 0
                })
                gsap.set([this.steps[1], this.steps[2]], {
                    autoAlpha: 0,
                    y: 20
                })

                this.timeline.to(this.path, {
                    drawSVG: '65%'
                })
                this.timeline.to(this.steps[1], {
                    autoAlpha: 1,
                    y: 0
                })
                this.timeline.to(this.path, {
                    drawSVG: '98%'
                })
                this.timeline.to(this.steps[2], {
                    autoAlpha: 1,
                    y: 0
                })
            })
        })
    }
}
export { RedInterventionProcess };