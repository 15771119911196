import gsap from "gsap"

class RedNis2 extends HTMLElement {
    static register(tagName) {
        if ("customElements" in window) {
            customElements.define(tagName || "red-nis2", RedNis2);
        }
    }

    form
    data
    etp
    sector
    radios
    radiosLabel

    constructor() {
        super()
        this.form = this.querySelector('form')
        this.radios = this.querySelectorAll('label:has(input[type="radio"])')
        this.radiosLabel = this.querySelectorAll('.nis2-tool__radio-name > div')
    }
    connectedCallback() {
        this.radios.forEach(radio=>{
            radio.addEventListener('click',()=>{
                const {value} = radio.querySelector('input')
                this.radiosLabel.forEach(label=>{
                    if(label.getAttribute('data-radio') == value){
                        label.style.display = "block"
                    }else{

                        label.style.display = "none"
                    }
                })
                
            })
        })
        this.form.addEventListener('submit', async (event) => {
            event.preventDefault()
            this.data = new FormData(this.form)
            this.sector = this.data.get('sector')
            this.etp = this.data.get('etp')
            const email = this.data.get('email')
            
            const isNis2 = this.isNis2()
            this.exitAnimation(isNis2)

            const res = await fetch('/data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email,
                    form:'nis2'
                })
            })

        })
    }
    isNis2() {
        let isEssential = false
        switch (parseInt(this.sector)) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
                if (this.etp == '250') {
                    isEssential = true
                }
                break;
            case 8:
                isEssential = true
                break;
            case 9: //Providers of public electronic communications networks
                if (this.etp != '50') {
                    isEssential = true
                }
                break;
            case 10:  // Non-qualified trust service providers
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:  // Managed (Security) Service Providers
                if (this.etp = '250') {
                    isEssential = true
                }
                break;
            case 16:  //Public administrations depending on the federal State 
                isEssential = true
                break;
            case 17:
            case 18: // Emergency zones & the fire and emergency medical service of the Brussels-Capital Region
                isEssential = false
                break;
            case 19: // Space
                if (this.etp == '250') {
                    isEssential = true
                }
                break;
            case 20:  // Non-qualified trust service providers
            case 21:
            case 22:
            case 23:
            case 24:  // Managed (Security) Service Providers
            case 25:
            case 26:
                isEssential = false
                break;
            default:
                console.log('default')
        }
        return isEssential
    }
    exitAnimation(isNis2){
        const tl = gsap.timeline()
        tl.to(this.querySelectorAll('form > *'), {
            opacity: 0,
            stagger: .1,
            pointerEvents:'none'
        })
        if(isNis2){
            this.querySelector('.nis2-tool__result .dont-applies').remove()
        }
        else{
            this.querySelector('.nis2-tool__result .applies').remove()
        }
        tl.to(this.querySelector('.nis2-tool__result'),{
            autoAlpha: 1,
            pointerEvents:'all'
        })
    }
}
export { RedNis2 };