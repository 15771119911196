import gsap from "gsap"
class RedCheques extends HTMLElement {
    static register(tagName) {
        if ("customElements" in window) {
            customElements.define(tagName || "red-cheques", RedCheques);
        }
    }
    form
    constructor() {
        super()
        this.form = this.querySelector('form')
    }
    connectedCallback() {
        this.form.addEventListener('submit', async (event) => {
            event.preventDefault()
            this.exitAnimation()
            const data = new FormData(this.form)
            const res = await fetch('/data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: data.get('email'),
                    form:'Chèques-Entreprise'
                })
            })
        })
    }
    exitAnimation() {
        const tl = gsap.timeline()
        tl.to(this.querySelectorAll('form > *'), {
            autoAlpha: 0,
            pointerEvents: 'none',
            stagger: .1
        })
        if (true) {
            this.querySelector('.cheques-tool__result-dont-applies').remove()
        }
        else {
            this.querySelector('.cheques-tool__result-applies').remove()
        }
        tl.to(this.querySelector('.cheques-tool__result'), {
            autoAlpha: 1,
            pointerEvents: 'all'
        })
    }
}
export { RedCheques };