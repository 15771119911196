import gsap from "gsap"
class RedChequesScroll extends HTMLElement {
    static register(tagName) {
        if ("customElements" in window) {
            customElements.define(tagName || "red-cheques-scroll", RedChequesScroll);
        }
    }
    context
    items
    path
    container
    constructor() {
        super()
        this.items = this.querySelectorAll('.cheques-scroll__item')
        this.container = this.querySelector('.cheques-scroll__list-container')
        this.path = this.querySelector('.cheques-scroll__path path')
    }
    disconnectedCallback() {
        this.context.revert()
    }
    connectedCallback() {
        this.context = gsap.context(() => {

            gsap.matchMedia().add('(min-width: 1152px)', () => {
                const totalWidth = this.items[0].clientWidth * (this.items.length - 3) + (96 * this.items.length - 1) + 96
                const tween = gsap.to(this.container, {
                    x: -totalWidth,
                    scrollTrigger: {
                        trigger: this.container,
                        start: 'center center',
                        end: '+=' + this.container.offsetWidth,
                        pin: true,
                        scrub: 1
                    }
                }, 0)
                gsap.from(this.path, {
                    drawSVG: '0%',
                    scrollTrigger: {
                        trigger: this.container,
                        start: 'center center',
                        end: '+=' + this.container.offsetWidth,
                        scrub: 1
                    }
                }, 0)
                Array.from(this.items).splice(2, this.items.length).forEach(item => {
                    gsap.from(item, {
                        opacity: 0,
                        y: 50,
                        scrollTrigger: {
                            trigger: item,
                            start: 'left 90%',
                            containerAnimation: tween,
                            markers: false,
                            toggleActions: "play none none reverse"
                        }
                    })
                })
            })
        })
    }
}
export { RedChequesScroll };