class RedWheel extends HTMLElement {
    static register(tagName) {
        if ("customElements" in window) {
            customElements.define(tagName || "red-wheel", RedWheel);
        }
    }
    buttons
    constructor() {
        super()
    }
    connectedCallback() {

        this.buttons = this.querySelectorAll('svg [data-text-background]')
        this.container = this.querySelector('svg')
        this.buttons.forEach((button,index)=>{
            button.addEventListener('click',()=>{
                this.container.style.setProperty('--angle', this.getAngle(button.dataset.textBackground) * (360 /7))
                this.container.dataset.current = button.dataset.textBackground
                this.dataset.current = button.dataset.textBackground
            })
        })
    }
    getAngle(index){
        if(index == 1) return 1
        if(index == 2) return 0
        if(index == 3) return -1
        if(index == 4) return -2
        if(index == 5) return -3
        if(index == 6) return 3
        if(index == 7) return 2
    }
}
export { RedWheel };