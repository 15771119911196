import gsap from "gsap"
import {ScrollTrigger} from 'gsap/ScrollTrigger'

class RedScrollingteam extends HTMLElement {
    static register(tagName) {
        if ("customElements" in window) {
            customElements.define(tagName || "red-scrollingteam", RedScrollingteam);
        }
    }
    constructor() {
        super()
    }
    connectedCallback() {
        const timeline = gsap.timeline({
            scrollTrigger:{
                trigger:this,
                start:'top bottom',
                end:'bottom top',
                scrub:4
            }
        })
        timeline.to(this.querySelector('.about-scrolling-team__inner'), {
            x: '-25vw',
            ease:'none'
        })
    }
}
export { RedScrollingteam };