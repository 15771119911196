class RedCount extends HTMLElement {

    static register(tagName) {
        if ("customElements" in window) {
            customElements.define(tagName || "red-count", RedCount);
        }
    }

    date = new Date('10/17/2024')
    today = new Date()
    remains = null
    elements = {}
    interval = null

    constructor() {
        super()
        this.elements.days = this.querySelector('[data-count="days"]')
        this.elements.hours = this.querySelector('[data-count="hours"]')
        this.elements.minutes = this.querySelector('[data-count="minutes"]')
        this.elements.seconds = this.querySelector('[data-count="seconds"]')
    }

    disconnectedCallback(){
        clearInterval(this.interval)
    }
    connectedCallback() {

        this.updateRemains()
       this.interval = setInterval(() => {
            this.updateRemains()
        }, 1000)
    }

    updateRemains() {
        this.today = new Date()
        const totalSeconds = Math.floor((this.date - this.today) / 1000)
        this.remains = {
            days: Math.floor(totalSeconds / (24 * 3600)),
            hours: Math.floor((totalSeconds % (24 * 3600)) / 3600),
            minutes: Math.floor((totalSeconds % 3600) / 60),
            seconds: totalSeconds % 60
        }
        this.updateDom()
    }

    updateDom(){
        this.elements.days.innerText = this.remains.days
        this.elements.hours.innerText = this.remains.hours
        this.elements.minutes.innerText = this.remains.minutes
        this.elements.seconds.innerText = this.remains.seconds
    }
}
export { RedCount };