import gsap from "gsap"
import barba from "@barba/core"
import AOS from "aos"
import { RedPoint } from "./components/red-point.js"
import ScrollTrigger from "gsap/ScrollTrigger"
import DrawSVGPlugin from "gsap/DrawSVGPlugin"
import { RedProcess } from "./components/red-process.js"
import { RedScrollingteam } from "./components/red-scrollingteam.js"
import { RedHelp } from "./components/red-help.js"
import { RedWheel } from "./components/red-wheel.js"
import { RedInterventionProcess } from "./components/red-intervention-process.js"
import { RedChequesScroll } from "./components/red-cheques-scroll.js"
import { RedCount } from "./components/red-count.js"
import { RedHeader } from "./components/red-header.js"
import { RedDropdown } from "./components/red-dropdown.js"
import { RedLang } from "./components/red-lang.js"
import { RedNis2 } from "./components/red-nis2.js"
import { RedCheques } from "./components/red-cheques.js"
import SplitText from "gsap/SplitText.js"
import { RedHero } from "./components/red-hero.js"

AOS.init()

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(DrawSVGPlugin)
gsap.registerPlugin(SplitText)

RedPoint.register()
RedProcess.register()
RedScrollingteam.register()
RedHelp.register()
RedWheel.register()
RedInterventionProcess.register()
RedChequesScroll.register()
RedCount.register()
RedHeader.register()
RedDropdown.register()
RedLang.register()
RedNis2.register()
RedCheques.register()
RedHero.register()

const { pathname } = new URL(window.location)

const timeline = gsap.timeline()

// timeline.add(() => {
//     document.querySelector('.loader').remove()
//     document.querySelector('.layout').style.clipPath = "none"
//     document.querySelector('.layout').style.overflow = "auto"
//     timeline.pause()
// })


timeline.to('.loader__logo', {
    y: -8,
    opacity: 1
})
timeline.to('.loader', {
    duration: 1
})
timeline.to('.loader__logo', {
    x: -133,
})
timeline.to('.loader__logo', {
    opacity: 0,
}, 'dot')
timeline.to('.point', {
    opacity: 1,
    duration: 0
}, 'dot')
timeline.to('.point', {
    width: '150vw',
    duration: 1
}, 'grow')

timeline.to('.layout', {
    clipPath: 'circle(100% at 50% 50%)',
    duration: 1
}, 'grow+=.1')

// timeline.from('.header',{
//     opacity:0
// })

timeline.add(() => {
    const body = document.querySelector('body')
    body.classList.add('load')
    document.querySelector('.loader').remove()
})



if (pathname == '/') {
    timeline.from((new SplitText(".home-hero__title", { type: "words" })).words, {
        opacity: 0,
        y: 20,
        stagger:.1
    })

    timeline.from((new SplitText(".home-hero__description", { type: "words" })).words, {
        opacity: 0,
        y: 5,
        stagger:.05
    },'same')
    timeline.from('.home-hero__logos-slide > *',{
        opacity:0,
        stagger:.05,
        y:10
    },'same')
    timeline.from('.home-introduction', {
        opacity:0,
        y: 20
    },'same')
    timeline.from('.home-hero__ctas > *',{
        opacity:0,
        x:10
    }, 'same')
}
else{

    const mySplitText = new SplitText(["[data-split-text]"], { type: "words" })

    timeline.from(mySplitText.words,{
        opacity:0,
        y: 20,
        stagger:.05
    })
}


barba.init({
    transitions: [
        {
            name: 'default',
            leave({ current, next }) {
                return gsap.to('.page-transition', {
                    clipPath: "polygon(0 0, 100% 0, 120% 100%, 0 100%)",
                    ease:'Power4.in'
                })

            },
            after({ current, next }) {
                const tl = gsap.timeline()
                const mySplitText = new SplitText(["[data-split-text]"], { type: "words" })
                tl.set('body',{
                    pointerEvents:'none'
                })
                tl.to('.page-transition', {
                    opacity:0,
                    ease:'Power4.out'
                })
                tl.set('.page-transition', {
                    clearProps: 'all'
                })
                tl.from(mySplitText.words,{
                    opacity:0,
                    y: 20,
                    stagger:.05
                })
                tl.set('body',{
                    pointerEvents:'unset'
                })
                return tl
            }
        }
    ]
})

barba.hooks.after(() => {
    AOS.refresh()
    ScrollTrigger.refresh()
    window.scrollTo(0,0)
})

window.addEventListener('resize', () => {
    ScrollTrigger.refresh()
})


console.log(`%c
██████  ███████ ██████  %c███████ ██    ██ ███████ ████████ ███████ ███    ███ 
%c██   ██ ██      ██   ██ %c██       ██  ██  ██         ██    ██      ████  ████ 
%c██████  █████   ██   ██ %c███████   ████   ███████    ██    █████   ██ ████ ██ 
%c██   ██ ██      ██   ██ %c     ██    ██         ██    ██    ██      ██  ██  ██ 
%c██   ██ ███████ ██████  %c███████    ██    ███████    ██    ███████ ██      ██ %c██ 

If you're looking for a job in cybersecurity,\nlook at our open positions: https://start.redsystem.io/jobs


`, "color: #E5212F;", "color:black;", "color: #E5212F;", "color:black;", "color: #E5212F;", "color:black;", "color: #E5212F;", "color:black;", "color: #E5212F;", "color:black;", "color: red;");


// type start() to launch the program.