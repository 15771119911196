class RedLang extends HTMLElement {
    static register(tagName) {
        if ("customElements" in window) {
            customElements.define(tagName || "red-lang", RedLang);
        }
    }
    button
    isOpen = false
    list
    constructor() {
        super()
        this.button = this.querySelector('button')
        this.list = this.querySelector('.lang-picker__list')
    }
    disconnectedCallback() {

        document.removeEventListener('click', this.documentListener)
    }
    connectedCallback() {
        this.button.addEventListener('click', (e) => {
            e.stopPropagation()
            if (this.getAttribute('data-lang-open') != null) {
                this.close()
            }
            else {
                this.open()
            }
        })
        document.addEventListener('click', this.documentListener)
    }
    documentListener = (event)=> {
        if (!event.composedPath().includes(this.list)) {
           this.close()
        }
    }
    open() {
        this.setAttribute('data-lang-open', '')
    }
    close() {
        this.removeAttribute('data-lang-open')
    }
}
export { RedLang };