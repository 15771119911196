import {MediaPlayer} from "dashjs"

class RedHero extends HTMLElement {
    static register(tagName) {
        if ("customElements" in window) {
            customElements.define(tagName || "red-hero", RedHero);
        }
    }

    url = "https://customer-pw6biwe0c4uqnzjf.cloudflarestream.com/221202689c35736e212993fd1e4a674d/manifest/video.mpd"
    element

    constructor() {
        super()
        this.element = this.querySelector('video')
    }
    connectedCallback() {
        const player = MediaPlayer().create()
        player.initialize(this.element, this.url, true)
    }
}
export { RedHero };