import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
class RedProcess extends HTMLElement {
    background
    icons = []
    context
    static register(tagName) {
        if ("customElements" in window) {
            customElements.define(tagName || "red-process", RedProcess);
        }
    }
    disconnectedCallback() {
        this.context.revert()
    }
    constructor() {
        super()
        this.background = this.querySelector('.about-process__icon svg:nth-child(1)')
        this.icons[0] = this.createCircleArray(2)
        this.icons[1] = this.createCircleArray(3)
        this.icons[2] = this.createCircleArray(4)
        this.icons[3] = this.createCircleArray(5)
    }
    connectedCallback() {
        this.context = gsap.context(() => {
            gsap.matchMedia().add('(min-width: 768px)', () => {
                const timeline = gsap.timeline({
                    scrollTrigger: {
                        trigger: this,
                        start: '350px center',
                        end: 'bottom-=350px center',
                        scrub: 2
                    }
                })
                timeline.to(this.icons[0], {
                    opacity: 0,
                    stagger: .1
                })
                timeline.fromTo(this.icons[1], {
                    opacity: 0
                }, {
                    opacity: 1,
                    stagger: .1
                })
                timeline.to('body',{
                    duration:4
                })
                timeline.to(this.icons[1], {
                    opacity: 0,
                    stagger: .1
                })
                timeline.fromTo(this.icons[2], {
                    opacity: 0
                }, {
                    opacity: 1,
                    stagger: .1
                })
                timeline.to('body',{
                    duration:4
                })
                timeline.to(this.icons[2], {
                    opacity: 0,
                    stagger: .1
                })
                timeline.fromTo(this.icons[3], {
                    opacity: 0
                }, {
                    opacity: 1,
                    stagger: .1
                })
            })
        })
    }
    createCircleArray(index) {
        return gsap.utils.shuffle(Array.from(this.querySelectorAll(`.about-process__icon svg:nth-child(${index}) circle`)))
    }
}
export { RedProcess };