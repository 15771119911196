class RedHeader extends HTMLElement {
    static register(tagName) {
        if ("customElements" in window) {
            customElements.define(tagName || "red-header", RedHeader);
        }
    }
    mobileMenuOpen = false
    hamburgerButton
    header
    body
    links
    dropdowns
    closeMobileButton
    constructor() {
        super()
        this.hamburgerButton = this.querySelector('[data-header="hamburger-button"]')
        this.header = this.querySelector('header')
        this.body = document.querySelector('body')
        this.links = this.querySelectorAll('a')
        this.dropdowns = this.querySelectorAll('.main-header__item--dropdown')
        this.closeMobileButton = this.querySelector('.mobile-nav__close-button')
    }
    connectedCallback() {
        this.hamburgerButton.addEventListener('click', () => { this.toggleNavigation() })
        this.links.forEach(link => link.addEventListener('click', () => { this.closeNavigation() }))
        this.closeMobileButton.addEventListener('click',()=>{this.closeNavigation()})
        this.setDropdowns()
    }
    toggleNavigation() {
        this.mobileMenuOpen = !this.mobileMenuOpen
        window.scrollTo(0,0)
        if (this.mobileMenuOpen) {
            this.body.setAttribute('data-header-open', '')
        }
        else {
            this.body.removeAttribute('data-header-open')
        }
    }
    closeNavigation() {
        this.mobileMenuOpen = false
        this.body.removeAttribute('data-header-open')
    }
    setDropdowns() {

        const closeDropdown = (dropdown) => {
            dropdown.removeAttribute('data-dropdown-open')
        }

        const openDropdown = (dropdown)=>{
            dropdown.setAttribute('data-dropdown-open', '')
        }

        this.dropdowns.forEach((dropdown) => {
            const button = dropdown.querySelector('[data-dropdown="button"]')

            document.addEventListener('click',(event)=>{
                if(!event.composedPath().includes(dropdown)){
                    closeDropdown(dropdown)
                }
            })

            button.addEventListener('click', () => {
                if (dropdown.getAttribute('data-dropdown-open') != null) {
                    closeDropdown(dropdown)
                }
                else {
                    openDropdown(dropdown)

                }
            })
        })
    }
}
export { RedHeader };