class RedHelp extends HTMLElement {
    static register(tagName) {
        if ("customElements" in window) {
            customElements.define(tagName || "red-help", RedHelp);
        }
    }

    options;
    current;
    constructor() {
        super()
        this.options = this.querySelectorAll('input')
    }
    connectedCallback() {
        this.options.forEach(option=>{
            option.checked=  false
            option.addEventListener('change', ()=>{
                this.dataset.type = option.value
            })
        })
    }
}
export { RedHelp };