import gsap from 'gsap'

class RedPoint extends HTMLElement {
    static register(tagName) {
        if ("customElements" in window) {
            customElements.define(tagName || "red-point", RedPoint);
        }
    }
    context
    constructor() {
        super()
    }
    disconnectedCallback() {
        this.context.revert()
    }
    connectedCallback() {
        const shouldStartWhenVisible = this.getAttribute('data-start') == 'visible'
        this.context = gsap.context(() => {

            const dots = gsap.utils.toArray('circle', this)
            const shuffled = gsap.utils.shuffle(dots)
            const timeline = gsap.timeline({
                scrollTrigger: {
                    trigger: this,
                    start: shouldStartWhenVisible ? 'top bottom' : 'top 80%',
                }
            })
            timeline.from(this, {
                duration: .3 * parseInt(this.dataset.index)
            })
            timeline.from(shuffled, {
                opacity: 0,
                stagger: .009,
            })
        })
    }
}
export { RedPoint };