class RedDropdown extends HTMLElement {
    static register(tagName) {
        if ("customElements" in window) {
            customElements.define(tagName || "red-dropdown", RedDropdown);
        }
    }
    constructor() {
        super()
    }
    connectedCallback() {
    }
}
export { RedDropdown };